@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow&family=Fira+Sans&family=Josefin+Sans:wght@300&family=Zen+Kurenaido&display=swap');

body {
  margin: 0;
  font-family:      'Archivo Narrow', sans-serif;
  font-family:      'Fira Sans', sans-serif;
  font-family:      'Josefin Sans', sans-serif;
  font-family:      'Zen Kurenaido', sans-serif;
  overflow:         scroll;
  background-color: #ece1e1;
}

::selection{
  background-color: #8B3611;
  color: #ffffff;
}

body::-webkit-scrollbar{
  display:          none;
}

a{
  text-decoration: none;
}

